<template>
<Html>
    <PageHead :title="title" :head="head" />
    <Body>
        <div class="bg-slate-800 flex justify-center items-center">
            <slot></slot>
        </div>
    </Body>
</Html>
</template>

<script setup>
const route = useRoute()
  const { t } = useI18n()
  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: false
  })
  const title = computed(() => t('layouts.title', { title: t(route.meta.title ?? 'TBD') }))
</script>



<style scoped>

</style>